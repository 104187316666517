import { Button } from 'baseui/button'
import { FocusOnce, Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from 'baseui/modal'
import { DisplayXSmall, LabelSmall, ParagraphSmall } from 'baseui/typography'
import { ChangeEvent } from 'react'
import { useState, useContext, useEffect } from 'react'
import Dni from '../../components/Dni'
import { StepperContext, STEPS } from '../../contexts/StepperContext'
import tokenService from '../../services/token-service'
import { theme } from '../../theming'
import styles from './Identification.module.scss'
import LoadingSpinner from '../../components/LoadingSpinner'
import { STATES } from '../App/App'
//import { validDocument } from '../../utils'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import operationService, { OperationOnboardingSteps } from '../../services/operations-service'
import VideoIdentification from '../../components/VideoIdentification'
import { personalizationTranslate } from '../../i18n'

export enum TOKENERROR {
  RESEND_OTP_OK = 10020,
  MAX_OTP_SENT = 10021
}

const Identification = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const params: any = {};
  searchParams.forEach((value: string, key: string) => {
    params[key] = value;
  });
  const { oId, sId } = params as {
    oId: string
    sId: string
  }
  const stepperContext = useContext(StepperContext)

  useEffect(() => {
    stepperContext.setCurrentStep(STEPS.IDENTIFICATION)
    stepperContext.clearSteps()
    getOperationOnboardingSteps()

    async function getOperationOnboardingSteps() {
      const operationResume = await operationService.getOnboardingSteps(oId, sId);
      setOperationOnboardingSteps(operationResume);
    }
  }, [])

  //const [isValid, setIsValid] = useState<boolean>(false)
  const [showIdentificationDni, setShowIdentificationDni] = useState<boolean>(false)
  const [showIdentificationVideoId, setShowIdentificationVideoId] = useState<boolean>(false)
  const [documentId, setDocumentId] = useState<string>('')
  const [isOpen, setOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)
  const [operationOnboardingSteps, setOperationOnboardingSteps] = useState<OperationOnboardingSteps | null>(null)

  useEffect(() => {
    if (operationOnboardingSteps) {
      // if (operationOnboardingSteps.state) {} => TODO - Dar error en caso de que la operación esté finalizada

      if (identificationVideoIdIsRequired(operationOnboardingSteps)) {
        setShowIdentificationVideoId(true)
      } else {
        setShowIdentificationDni(true)
      }
    }

    function identificationVideoIdIsRequired(operationOnboardingSteps: OperationOnboardingSteps): Boolean {
      if ((operationOnboardingSteps.identificationVideoId.required === true) && (operationOnboardingSteps.identificationVideoId.validated === false)) {
        return true
      }
      return false
    }
    
  }, [operationOnboardingSteps])

  function handleInputChange(event: ChangeEvent<HTMLInputElement>): void {
    const value = event.currentTarget.value.toUpperCase()
    //setIsValid(validDocument(value))
    setDocumentId(value)
  }
  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault()
    //if (isValid) {
    await getToken(documentId);
    // }
    //}
  }

  async function getToken(documentId: string) {
    try {
      const token = await tokenService.getAccessToken(oId, sId, documentId)
      if (token) {
        setIsLoading(true)
        if (token.operation.state !== 1) {
          setOpen(true)
        } else if (token.operation.signer.state === 5) {
          navigate(`/otp?${searchParams.toString()}&token=${token.accessToken}`)
        } else if (token.operation.signer.state === 2) {
          navigate(`/finishedsign?requireEmailValidation=true&oId=${oId}&sId=${sId}`)
        } else {
          navigate(`/sign?${searchParams.toString()}&token=${token.accessToken}`)
        }
      }
    } catch (err: any) {
      console.error(err);
      setIsLoading(true)
      if (err.response?.status === 400) {
        setOpen(true)
        setIsLoading(false)
      } else {
        if (err.response?.data?.code === TOKENERROR.RESEND_OTP_OK) {
          localStorage.setItem('resend', 'true')
          navigate(`/otp?${searchParams.toString()}&documentId=${documentId}`)
        } else if (err.response?.data?.code === TOKENERROR.MAX_OTP_SENT) {
          localStorage.setItem('resend', 'false')
          navigate(`/otp?${searchParams.toString()}&documentId=${documentId}`)
        } else {
          console.log(err)
          throw err
          // router.push({
          //   pathname: '/',
          //   query: {
          //     oId,
          //     sId,
          //     eStatus: STATES.NON_PENDANT_OPERATION
          //   }
          // })
        }
      }
    }
  }

  return (
    <>
      {
        <Modal isOpen={isOpen} closeable={false}>
          <ModalHeader>{personalizationTranslate('elements.errors.notPendantOperationTitle', i18n.language)}</ModalHeader>
          <FocusOnce>
            <ModalBody>{personalizationTranslate('elements.errors.notPendantOperationText', i18n.language)}</ModalBody>
          </FocusOnce>
          <ModalFooter>
            <ModalButton
              autoFocus
              onClick={() => {
                setOpen(false)
                setIsLoading(false)
              }}>
              {t('ACCEPT')}
            </ModalButton>
          </ModalFooter>
        </Modal>
      }

      <form onSubmit={handleSubmit} className={styles.container}>
        <DisplayXSmall>{t('CONFIRM_THE_DATA')}</DisplayXSmall>
        {theme.elements.identification.showIds && (
          <div className={styles.idsContainer}>
            <div className={styles.idInner}>
              <LabelSmall>{t('OPERATION_ID')}:</LabelSmall>
              <ParagraphSmall>{oId}</ParagraphSmall>
            </div>
            <div className={styles.idInner}>
              <LabelSmall>{t('SIGNER_ID')}:</LabelSmall>
              <ParagraphSmall>{sId}</ParagraphSmall>
            </div>
          </div>
        )}

        {showIdentificationDni && (
          <Dni
            value={documentId}
            change={handleInputChange}
            isLoading={isLoading}
            //isValid={isValid}
          />
        )}

        {showIdentificationVideoId && (
          <VideoIdentification oId={oId} sId={sId} setDocumentId={setDocumentId} onGetSignerIdentificationNumber={getToken}/>
        )}
      </form>
    </>
  )
}
export default Identification
