import React, { useEffect } from 'react'
import { Draw } from '../services/operations-service'
import { DocumentI } from './DocumentsList'

import { RubricaePdfjsViewer } from '@rubricae/pdfjs-webcomponent-react';
import styles from './PdfViewer.module.scss';

export default function PdfViewer({
  file,
  signature,
  draws
}: {
  file: DocumentI | undefined
  signature: any
  draws: Draw[]
}) {
  const [isLoaded, setIsLoaded] = React.useState(false);

  const drawSignature = () => {
    if (signature.content) {
      console.log((window as any).PDFViewerApplication.pdfViewer);
      
      (window as any).PDFViewerApplication.pdfViewer.removeRegisteredAnotations();

      for (let draw of draws) {
        if (file?.name === draw.filename) {
          (window as any).PDFViewerApplication.pdfViewer.registerStampAnnotation({
            page: draw.page,
            top: draw.top,
            left: draw.left,
            width: draw.width,
            height: draw.height,
            imageUrl: signature.content,
            block: draw.options?.lockSignature || true
          });
        }
      }
    }
  }

  useEffect(() => {
    drawSignature()
  }, [signature])

  useEffect(() => {
    (async function asyncEffect() {
      await openDocumentOnPdfViewer();
      setTimeout(() => {
        drawSignature();
      }, 100);
    })();
  }, [file])

  useEffect(() => {
    setIsLoaded(true);
  }, [])

  async function openDocumentOnPdfViewer() {
    if ((window as any).PDFViewerApplication && file?.content) {
      return (window as any).PDFViewerApplication.open({
        data: atob(file.content)
      });
    }
  }

  return (
    <>
      {file?.content && isLoaded && (
        <RubricaePdfjsViewer className={styles.rubricaePdfjsViewer} id="rubricae-pdfjs-viewer" pdf={file.content}></RubricaePdfjsViewer>
      )}
    </>
  )
}
