import { createContext, useState } from 'react'

export const StepperContext = createContext<any>(null)

export enum STEPS {
  IDENTIFICATION = 'IDENTIFICATION',
  DOCUMENT_SIGN = 'DOCUMENT_SIGN',
  OTP_VERIFICATION = 'OTP_CONFIRMATION',
  SEND_EMAIL = 'SEND_EMAIL',
  SIGN_FINISHED = 'SIGN_FINISHED',
  EMAIL_VALIDATED_SIGN = 'EMAIL_VALIDATED_SIGN'
}

export const StepperContextProvider = ({ children }: any) => {
  const [currentStep, setCurrentStep] = useState<string | null>(null)
  const [steps, setSteps] = useState<string[]>([])

  const handleSteps = (stepper: any) => {
    let aux = [STEPS.IDENTIFICATION]
    if (stepper === null) {
      aux.push(STEPS.OTP_VERIFICATION, STEPS.DOCUMENT_SIGN, STEPS.SIGN_FINISHED)
    } else {
      if (stepper?.signer.requireAccessOtp) {
        aux.push(STEPS.OTP_VERIFICATION, STEPS.DOCUMENT_SIGN)
      }
      if (stepper?.signer.requireSignatureOtp) {
        aux.push(STEPS.DOCUMENT_SIGN, STEPS.OTP_VERIFICATION)
      }
      if (
        !stepper?.signer.requireAccessOtp &&
        !stepper?.signer.requireSignatureOtp &&
        !stepper?.signer.requireEmailValidation
      ) {
        aux.push(STEPS.DOCUMENT_SIGN, STEPS.SIGN_FINISHED)
      }
      if (
        !stepper?.signer.requireAccessOtp &&
        !stepper?.signer.requireSignatureOtp &&
        stepper?.signer.requireEmailValidation
      ) {
        aux.push(STEPS.DOCUMENT_SIGN)
      }
      if (
        stepper?.signer.requireEmailValidation &&
        !stepper?.signer.requireSignatureOtp
      ) {
        aux.push(STEPS.SEND_EMAIL)
      } else if (
        stepper?.signer.requireEmailValidation &&
        !stepper?.signer.requireAccessOtp
      ) {
        aux.push(STEPS.SEND_EMAIL)
      } else if (
        stepper?.signer.requireEmailValidation &&
        stepper?.signer.requireAccessOtp
      ) {
        aux.push(STEPS.SEND_EMAIL)
      }
      if (!aux.includes(STEPS.SIGN_FINISHED)) {
        aux.push(STEPS.SIGN_FINISHED)
      }
    }
    setSteps(aux)
  }

  const clearSteps = () => {
    setSteps([])
  }

  const value: any = {
    steps,
    currentStep,
    setCurrentStep,
    handleSteps,
    clearSteps
  }

  return (
    <StepperContext.Provider value={value}>{children}</StepperContext.Provider>
  )
}
